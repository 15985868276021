import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { PageContentLayout } from '../../../components/PageContentLayout';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageContentLayout headProps={{
  title: 'Bleeps Categories'
}} {...props} />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Bleeps Categories`}</h1>
    <p>{`The bleeps in an application can be configured by predefined categories.
Each category can have the audio settings which will be applicable to the
respective bleeps. This can allow the user to have different
audio settings for the application experience.`}</p>
    <p>{`An example application with audio settings by categories can look like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`import React, { FC } from 'react';
import {
  BleepsProvider,
  BleepsAudioSettings,
  BleepsPlayersSettings,
  BleepsSettings
} from '@arwes/sounds';

const audioSettings: BleepsAudioSettings = {
  common: {
    volume: 0.75
  },
  categories: {
    interaction: {
      volume: 0.5
    },
    notification: {
      volume: 1
    }
  }
};

const playersSettings: BleepsPlayersSettings = {
  click: {
    src: ['/path/to/sounds/click.webm']
  },
  warning: {
    src: ['/path/to/sounds/warning.webm']
  }
};

const bleepsSettings: BleepsSettings = {
  click: {
    player: 'click',
    category: 'interaction'
  },
  warning: {
    player: 'warning',
    category: 'notification'
  }
};

const App: FC = ({ children }) => (
  <BleepsProvider
    audioSettings={audioSettings}
    playersSettings={playersSettings}
    bleepsSettings={bleepsSettings}
  >
    {children}
  </BleepsProvider>
);
`}</code></pre>
    <p>{`All bleeps would start with a volume of `}<inlineCode parentName="p">{`0.75`}</inlineCode>{`. If they are `}<inlineCode parentName="p">{`interaction`}</inlineCode>{` bleeps,
volume is `}<inlineCode parentName="p">{`0.5`}</inlineCode>{`, and if they are `}<inlineCode parentName="p">{`notification`}</inlineCode>{` bleeps, volume is `}<inlineCode parentName="p">{`1`}</inlineCode>{`.`}</p>
    <p>{`The categories can also be disabled with `}<inlineCode parentName="p">{`disabled: true`}</inlineCode>{`.`}</p>
    <p>{`The following predefined categories are available:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`background`}</inlineCode>{` - For components low volume background effects.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`transition`}</inlineCode>{` - For animation flow transitions.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`interaction`}</inlineCode>{` - For user events such as mouse clicks.`}</li>
      <li parentName="ul"><inlineCode parentName="li">{`notification`}</inlineCode>{` - For application events such as real-time chat messages.`}</li>
    </ul>
    <p>{`It is recommended to allow the user to update the audio settings by categories
in the application.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      